import * as React from 'react'
import { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Typography, Modal, Grid, Divider, Box } from '@material-ui/core'
import { styles } from './styles'
import { TFunction } from 'i18next'
import { Link } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined'
import CloseIcon from '@material-ui/icons/Close'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share'
import { isIOS, isMacOs } from 'react-device-detect'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import CHRImage from './CHRImage'
import SignInModal from '../../signInModal'
import CreateProductShareLink from '../../createProductShareLink'
import SocialShareLink from '../../SocialShareLink'
import { tracker } from '../../../systemLogs'
import useAdmin from '../../../hooks/useAdmin'
import axios from '../../../axios'
import { errorToast } from '../../../../utils/errToast'
import emailIcon from '../../../assets/images/dark-email.svg'
import facebookIcon from '../../../assets/images/facebook.svg'
import twiterIcon from '../../../assets/images/twitter.svg'
import clipboard from '../../../assets/images/clipboard.svg'
import pinterest from '../../../assets/images/pinterest.svg'
import heartFilled from '../../../assets/images/heart_filled.svg'
import heart from '../../../assets/images/heart_unfilled.svg'
import { BREAKPOINTS, ENDPOINTS } from '../../../constants'
import { useAppState } from '../../../appState'
import {
  generateTrackingLink,
  urlSafeProductName,
} from '../../../../utils/brandsHelper'
import { TrackingCase } from '../../../../utils/trackingCases'

declare const window: any

interface CardProps {
  productInfo: {
    product_id: string
    thumbnail_url: string
    cashBack: string
    name: string
    brand_name: string
    brand_id: string
    network_id: number
    price: number
    original_price: number
    tracking_url: string
    product_url: string
    isAdded: boolean
    description: string
    images_urls: string[]
    shoppingBoardId: number
    trendingId?: number
    clicksCount?: number
    sold?: number
  }
  t: TFunction
  userId: number
  updateProducts: Function
  onRemove?: Function
  isTopItem: boolean
  shareable: boolean
}

const Card = ({
  t,
  userId,
  updateProducts,
  onRemove,
  isTopItem,
  shareable,
  productInfo: {
    product_id,
    thumbnail_url,
    cashBack,
    clicksCount,
    sold,
    name,
    brand_name,
    brand_id,
    price,
    original_price,
    tracking_url,
    product_url,
    description,
    network_id,
    images_urls,
    shoppingBoardId,
    trendingId,
  },
}: CardProps) => {
  const [appState] = useAppState()
  const [isAdmin] = useAdmin()
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery(`@media (min-width: ${BREAKPOINTS.lg}px)`)
  const [shortenedLink, setShortenedLink] = useState({
    value: '',
    commission: '',
    isLoading: false,
  })
  const [added, setAdded] = useState(Boolean(shoppingBoardId))
  const [loading, setLoading] = useState(false)
  const classes = styles()
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    appState.userId && setIsLoggedIn(true)
  }, [])

  const getProductUrl = () => {
    let url = `/p/${urlSafeProductName(name, brand_name)}/${product_id}`
    if (trendingId) url += `?trending=${trendingId}`
    return url
  }

  const changeWindowUrl = (title: string, url: string) => {
    if (typeof history.pushState !== 'undefined') {
      const Title = title
      const Url = url

      history.pushState({ Title, Url }, Title, Url)
    }
  }

  const handleClose = () => {
    const productUrl = getProductUrl().split('?')[0]
    if (window.location.pathname === productUrl) {
      shareable && history.back()
    }

    setOpen(false)
    window.removeEventListener('popstate', handleClose)
  }

  const handleOpen = () => {
    if (shareable) {
      window.addEventListener('popstate', handleClose)
      changeWindowUrl(location.pathname, getProductUrl())
    }
    setOpen(true)
    if (appState.username) {
      window.klaviyoTrigger('Product Viewed', product_id, name)
    }
  }

  const closeSignInModal = () => {
    setIsOpenSignInModal(false)
  }

  const trackingLink = generateTrackingLink(
    {
      network: network_id,
      trackingLink: tracking_url,
      url: product_url,
      brandName: brand_name,
      commission: cashBack,
    },
    {
      userId: `${userId || 0}`,
      userName: appState.userProfile?.userName,
    },
    product_id,
    '',
    Boolean(userId)
  )
  const trackingLinkNoRedirect = generateTrackingLink(
    {
      network: network_id,
      trackingLink: tracking_url,
      url: product_url,
      brandName: brand_name,
      commission: cashBack,
    },
    {
      userId: `${userId || 0}`,
      userName: appState.userProfile?.userName,
    },
    product_id,
    '',
    false
  )

  const handleAddToShoppingBoard = async () => {
    if (!isLoggedIn) {
      setIsOpenSignInModal(true)
      handleClose()
      return
    }
    setAdded(true)
    setLoading(true)
    tracker.track(
      TrackingCase.UserTracking,
      `POST ${ENDPOINTS.addToShoppingBoard}`,
      { location: 'Card', barndId: brand_id, productId: product_id }
    )
    const res = await axios.post(ENDPOINTS.addToShoppingBoard, {
      brandId: brand_id,
      type: 'product',
      productId: product_id,
      data: {
        name,
        imageUrl: thumbnail_url,
        price:
          isNaN(price) || String(price) === ''
            ? `${price}`
            : '$' + price.toFixed(2),
        originalPrice:
          isNaN(original_price) || String(original_price) === ''
            ? `${original_price}`
            : '$' + original_price.toFixed(2),
        brandId: brand_id,
        brandName: brand_name,
        networkId: network_id,
        link: product_url,
        tracking_url: tracking_url,
      },
    })
    setLoading(false)
    updateProducts(product_id || trendingId, res.data.data.id)
    if (appState.username) {
      window.itemsFavourite(
        name.replace(/"/g, ''),
        {
          name: name.replace(/"/g, ''),
          imageUrl: thumbnail_url,
          price,
          brandId: brand_id,
          brandName: brand_name,
          networkId: network_id,
          link: product_url,
          tracking_url: tracking_url,
        },
        (price + '').replace(/\$/g, '')
      )
    }
  }

  const handleRemoveFromShoppingBoard = async () => {
    setAdded(false)
    setLoading(true)
    tracker.track(
      TrackingCase.UserTracking,
      `DELETE ${ENDPOINTS.deleteShoppingBoardItem}`,
      { location: 'Card', SBoardId: shoppingBoardId }
    )
    await axios.delete(
      ENDPOINTS.deleteShoppingBoardItem.replace(':id', shoppingBoardId)
    )
    setLoading(false)
  }

  const handleSMSButtonClick = () => {
    if (isIOS || isMacOs) {
      window.open(
        `sms:?&body=${t('createLink.smsMessage') + shortenedLink.value}`,
        '_self'
      )
    } else {
      window.open(
        `sms:?body=${t('createLink.smsMessage') + shortenedLink.value}`,
        '_self'
      )
    }
  }

  const toggleAddingToShoppingBoard = async () => {
    try {
      if (added) {
        await handleRemoveFromShoppingBoard()
      } else {
        await handleAddToShoppingBoard()
      }
    } catch (err) {
      setTimeout(() => {
        setLoading(false)
        setAdded(s => !s)
      }, 500)
    }
  }

  const deleteProduct = async () => {
    tracker.track(
      TrackingCase.UserTracking,
      `POST ${ENDPOINTS.deleteProduct}`,
      { location: 'Card', networkId: network_id, productId: product_id }
    )
    try {
      const res = await axios.post(ENDPOINTS.deleteProduct, {
        productId: product_id,
        networkId: network_id,
      })
      if (res.data.data.success) onRemove && onRemove(product_id)
    } catch (err) {
      errorToast(`couldn't remove item, please try again`)
    }
  }

  const onAddToShoppingBoardClick = async () => {
    try {
      await handleAddToShoppingBoard()
    } catch (err) {
      setTimeout(() => setAdded(false), 500)
    }
  }

  const truncate = (text: string = '') => {
    if (text.length > 250) {
      return text.slice(0, 250) + '...'
    }
    return name
  }

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {isDesktop ? (
          <CHRImage
            src={thumbnail_url}
            alt={name}
            className={classes.image}
            onClick={handleOpen}
          />
        ) : (
          <a
            target="_blank"
            href={trackingLink}
            className={classes.anchorWidth}
          >
            <CHRImage
              src={thumbnail_url}
              alt={name}
              className={classes.image}
            />
          </a>
        )}
        {!isTopItem && (
          <>
            <button
              disabled={loading}
              onClick={toggleAddingToShoppingBoard}
              className={classes.heart}
            >
              <img
                src={added ? heartFilled : heart}
                alt="heart-icon"
                width="22"
                height="20"
              />
            </button>
            {isAdmin && onRemove && (
              <button
                disabled={loading}
                onClick={deleteProduct}
                className={classes.deleteIcon}
              >
                <CloseIcon />
              </button>
            )}
          </>
        )}
        {isDesktop && (
          <>
            <a
              className={classes.shopButton}
              target="_blank"
              href={trackingLink}
            >
              {t('shared.shop')}
            </a>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-top-products"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.paper}>
                <div className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon />
                </div>
                <Grid container flex alignItems="center">
                  <Grid item lg={6}>
                    <Carousel
                      swipeable={true}
                      showArrows={true}
                      showThumbs={false}
                      emulateTouch={true}
                      showStatus={false}
                    >
                      {[thumbnail_url]
                        .concat(images_urls)
                        .map((image, index) => {
                          return (
                            <div key={index} style={{ height: '100%' }}>
                              <CHRImage
                                src={image}
                                alt={name}
                                draggable={false}
                                errStyle={classes.errImage}
                                className={classes.productImage}
                                style={{ pointerEvents: 'all' }}
                              />
                            </div>
                          )
                        })}
                    </Carousel>
                  </Grid>
                  <Grid item lg={6} className={classes.modalInfo}>
                    <Typography variant="h3" className={classes.modalInfoTitle}>
                      {name}
                    </Typography>
                    <Grid container>
                      <Grid item lg={8}>
                        <Typography variant="h3">
                          {t('shared.from')}{' '}
                          <Link
                            to={`/featured-brands/${brand_name.replace(
                              /\s+|\/|\\|#/g,
                              '-'
                            )}/${brand_id}`}
                            className={classes.brandLink}
                          >
                            {brand_name}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item lg={4}>
                        <Typography
                          variant="h3"
                          className={classes.modalInfoPrice}
                        >
                          {isNaN(price) || String(price) === ''
                            ? `${price}`
                            : '$' + price.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Typography
                      variant="subtitle1"
                      className={classes.modalInfoDescription}
                    >
                      {truncate(description)}
                    </Typography>
                    <a
                      className={`${classes.modalButton} ${classes.blackBgButton}`}
                      target="_blank"
                      href={trackingLink}
                    >
                      <Typography variant="button">
                        {t('shared.shop')}
                      </Typography>
                    </a>
                    <Divider />
                    <Box mt={1} />
                    <CreateProductShareLink
                      networkId={network_id}
                      productId={product_id}
                      productUrl={product_url}
                      productName={name}
                      productImage={thumbnail_url}
                      shortenedLink={shortenedLink}
                      setShortenedLink={s => setShortenedLink(s)}
                      isLoggedIn={isLoggedIn}
                      handleOpenModal={() => setIsOpenSignInModal(true)}
                    />
                    <button
                      className={`${classes.modalButton} ${classes.whiteBgButton} ${classes.addToShoppingBoard}`}
                      onClick={onAddToShoppingBoardClick}
                      disabled={added}
                    >
                      <div className={classes.addToboardContainer}>
                        <img src={clipboard} alt={t('createLink.clipboard')} />
                        <Typography
                          variant="button"
                          className={classes.addToboardText}
                        >
                          {added
                            ? t('createLink.addedToBoard')
                            : t('createLink.addToboard')}
                        </Typography>
                      </div>
                    </button>
                    <Typography
                      variant="subtitle1"
                      className={classes.socialTitle}
                    >
                      {t('shared.orShareVia')}
                    </Typography>

                    {/*   ----   social links  ---   */}
                    <Grid container justifyContent="center" alignItems="center">
                      <div className={classes.socialButton}>
                        <SocialShareLink
                          onInitial={() =>
                            setShortenedLink({
                              value: '',
                              commission: '',
                              isLoading: true,
                            })
                          }
                          onSuccess={data =>
                            setShortenedLink({
                              value: data.shortUrl,
                              commission: data.commission,
                              isLoading: false,
                            })
                          }
                          onFail={() => setIsOpenSignInModal(true)}
                          shortenedLink={shortenedLink.value}
                          shortLinkData={{
                            url: product_url,
                            productId: product_id,
                            productName: name,
                            productImage: thumbnail_url,
                          }}
                          isLoggedIn={isLoggedIn}
                        >
                          {(ref, url) => (
                            <>
                              <EmailShareButton
                                ref={ref}
                                subject={t('createLink.emailSubject')}
                                body={t('createLink.emailBody')}
                                url={url}
                                style={{
                                  display: 'none',
                                }}
                              >
                                <img
                                  src={emailIcon}
                                  alt={t('shared.email')}
                                  className={classes.socialIcon}
                                />
                              </EmailShareButton>
                              <img
                                src={emailIcon}
                                alt={t('shared.email')}
                                className={classes.socialIcon}
                              />
                            </>
                          )}
                        </SocialShareLink>
                      </div>

                      <div className={classes.socialButton}>
                        <SocialShareLink
                          onInitial={() =>
                            setShortenedLink({
                              value: '',
                              commission: '',
                              isLoading: true,
                            })
                          }
                          onSuccess={data =>
                            setShortenedLink({
                              value: data.shortUrl,
                              commission: data.commission,
                              isLoading: false,
                            })
                          }
                          onFail={() => setIsOpenSignInModal(true)}
                          shortenedLink={shortenedLink.value}
                          shortLinkData={{
                            url: product_url,
                            productId: product_id,
                            productName: name,
                            productImage: thumbnail_url,
                          }}
                          isLoggedIn={isLoggedIn}
                        >
                          {ref => (
                            <>
                              <div
                                ref={ref}
                                style={{
                                  display: 'none',
                                }}
                                onClick={handleSMSButtonClick}
                              >
                                <ChatBubbleOutlineOutlinedIcon />
                              </div>
                              <ChatBubbleOutlineOutlinedIcon />
                            </>
                          )}
                        </SocialShareLink>
                      </div>

                      <div className={classes.socialButton}>
                        <SocialShareLink
                          onInitial={() =>
                            setShortenedLink({
                              value: '',
                              commission: '',
                              isLoading: true,
                            })
                          }
                          onSuccess={data =>
                            setShortenedLink({
                              value: data.shortUrl,
                              commission: data.commission,
                              isLoading: false,
                            })
                          }
                          onFail={() => setIsOpenSignInModal(true)}
                          shortenedLink={shortenedLink.value}
                          shortLinkData={{
                            url: product_url,
                            productId: product_id,
                            productName: name,
                            productImage: thumbnail_url,
                          }}
                          isLoggedIn={isLoggedIn}
                        >
                          {(ref, url) => (
                            <>
                              <FacebookShareButton
                                ref={ref}
                                url={url}
                                quote={t('createLink.socialMessage')}
                                style={{
                                  display: 'none',
                                }}
                              >
                                <img
                                  src={facebookIcon}
                                  alt={t('imageAlts.facebook')}
                                  className={classes.socialIcon}
                                />
                              </FacebookShareButton>
                              <img
                                src={facebookIcon}
                                alt={t('imageAlts.facebook')}
                                className={classes.socialIcon}
                              />
                            </>
                          )}
                        </SocialShareLink>
                      </div>

                      <div className={classes.socialButton}>
                        <SocialShareLink
                          onInitial={() =>
                            setShortenedLink({
                              value: '',
                              commission: '',
                              isLoading: true,
                            })
                          }
                          onSuccess={data =>
                            setShortenedLink({
                              value: data.shortUrl,
                              commission: data.commission,
                              isLoading: false,
                            })
                          }
                          onFail={() => setIsOpenSignInModal(true)}
                          shortenedLink={shortenedLink.value}
                          shortLinkData={{
                            url: product_url,
                            productId: product_id,
                            productName: name,
                            productImage: thumbnail_url,
                          }}
                          isLoggedIn={isLoggedIn}
                        >
                          {(ref, url) => (
                            <>
                              <TwitterShareButton
                                ref={ref}
                                url={url}
                                title={t('createLink.socialMessage')}
                                style={{
                                  display: 'none',
                                }}
                              >
                                <img
                                  src={twiterIcon}
                                  alt={t('imageAlts.twitter')}
                                  className={classes.socialIcon}
                                />
                              </TwitterShareButton>
                              <img
                                src={twiterIcon}
                                alt={t('imageAlts.twitter')}
                                className={classes.socialIcon}
                              />
                            </>
                          )}
                        </SocialShareLink>
                      </div>

                      <div className={classes.socialButton}>
                        <SocialShareLink
                          onInitial={() =>
                            setShortenedLink({
                              value: '',
                              commission: '',
                              isLoading: true,
                            })
                          }
                          onSuccess={data =>
                            setShortenedLink({
                              value: data.shortUrl,
                              commission: data.commission,
                              isLoading: false,
                            })
                          }
                          onFail={() => setIsOpenSignInModal(true)}
                          shortenedLink={shortenedLink.value}
                          shortLinkData={{
                            url: product_url,
                            productId: product_id,
                            productName: name,
                            productImage: thumbnail_url,
                          }}
                          isLoggedIn={isLoggedIn}
                        >
                          {(ref, url) => (
                            <>
                              <PinterestShareButton
                                ref={ref}
                                url={trackingLinkNoRedirect}
                                description={t('createLink.socialMessage')}
                                media={thumbnail_url}
                                style={{
                                  display: 'none',
                                }}
                              >
                                <img
                                  src={pinterest}
                                  alt={t('imageAlts.pinterest')}
                                  className={classes.socialIcon}
                                />
                              </PinterestShareButton>
                              <img
                                src={pinterest}
                                alt={t('imageAlts.pinterest')}
                                className={classes.socialIcon}
                              />
                            </>
                          )}
                        </SocialShareLink>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </>
        )}
        {isTopItem && (
          <Typography
            component="p"
            className={`${classes.topItemCashBack} ${classes.productInfo}`}
          >
            <span
              style={{
                width: '33%',
                display: 'inline-block',
                marginTop: '0.75rem',
                marginBottom: '0.75rem',
              }}
            >
              {`${clicksCount || 0} CLICKS`}
            </span>
            <span
              style={{
                width: '33%',
                display: 'flex',
                margin: '0 auto',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'white',
              }}
            >
              {`${sold} SOLD`}
            </span>
            <span
              style={{
                width: '33%',
                display: 'inline-block',
                marginTop: '0.75rem',
                marginBottom: '0.75rem',
              }}
            >
              {`EARNED $${cashBack || 0}`}
            </span>
          </Typography>
        )}
        {!isTopItem && cashBack !== '' && (
          <Typography
            component="p"
            className={`${classes.cashBack} ${classes.productInfo}`}
          >
            {`up to ${cashBack} cash back`}
          </Typography>
        )}
      </div>
      <div className={classes.nameAndPrice}>
        <Typography
          component="h2"
          className={`${classes.productName} ${classes.productInfo}`}
        >
          {product_id ? (
            <Link
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={getProductUrl()}
            >
              {name}
            </Link>
          ) : (
            <a
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              href={trackingLink}
              target="_blank"
            >
              {name}
            </a>
          )}
        </Typography>
      </div>
      <Typography
        variant="subtitle1"
        component="p"
        className={classes.retailerName}
      >
        {brand_name}
      </Typography>
      {price < original_price ? (
        <div>
          <Typography
            component="p"
            className={`${classes.lineThrough} ${classes.productInfo} ${classes.mobileEdgeDevice}`}
          >
            ${original_price.toFixed(2)}
          </Typography>
          <Typography
            component="p"
            className={`${classes.salePrice} ${classes.productInfo} ${classes.mobileEdgeDevice}`}
          >
            {isNaN(price) || String(price) === ''
              ? `${price}`
              : '$' + price.toFixed(2)}
          </Typography>
        </div>
      ) : (
        <Typography
          component="p"
          className={`${classes.productInfo} ${classes.mobileEdgeDevice}`}
        >
          {isNaN(price) || String(price) === ''
            ? `${price}`
            : '$' + price.toFixed(2)}
        </Typography>
      )}
      <SignInModal
        handleClose={closeSignInModal}
        openStatus={isOpenSignInModal}
        setIsLoggedIn={setIsLoggedIn}
      />
    </div>
  )
}

export default withTranslation()(Card)
